import React, { useEffect, useRef, useState } from "react";
import "./Register.css";
import logo from "../assets/images/logo.svg";
import line from "../assets/images/line.svg";
import video from "../assets/images/video.png";
import interactive from "../assets/images/interactive.svg";
import lifetime from "../assets/images/lifetime.svg";
import flexible from "../assets/images/flexible.svg";
import user from "../assets/images/user.png";
import { Link, NavLink, useHistory } from "react-router-dom";
import jQuery from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { HashLink } from "react-router-hash-link";
import {
  countries,
  states,
  age_groups,
  instruments,
} from "../assets/data/DropdownData";
import { registerUser, resendOtp, sendOtp, verifyOtp } from "../Services/singup.services";
import { toast } from "react-toastify";
import ReactPlayer from "react-player/youtube";
import { AiFillCheckCircle, AiFillPlayCircle, AiOutlineCheck, AiOutlineCheckCircle } from "react-icons/ai";
import { reviewData } from "../assets/data/review.data";
import { Nav } from "react-bootstrap";
import { FaCheck } from 'react-icons/fa';
import { TrackGoogleAnalyticsEvent } from "../utils/ga4";

interface IPhone {
  phone: string;
  countryCode: string;
  utms: {
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_content: string;
  };
}

interface IRegister {
  country: string;
  firstName: string;
  lastName: string;
  email: string;
  ageGroup: string;
  state: string;
  instrument: string;
}

const RegisterStudent = () => { 
  const StarSvg = () => (
    <span className="mr-2">
      <svg
        width="12"
        height="11"
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.9083 10.3425C9.9083 10.6825 9.74827 10.8325 9.43827 10.6825L6.88829 9.3925C6.7115 9.316 6.52092 9.27652 6.32829 9.27652C6.13566 9.27652 5.94508 9.316 5.76829 9.3925L3.20829 10.6525C2.90829 10.8025 2.69827 10.6525 2.74827 10.3125L3.17826 7.49249C3.19613 7.30046 3.17527 7.1068 3.11686 6.92299C3.05846 6.73919 2.96374 6.569 2.8383 6.4225L0.838297 4.4225C0.598297 4.1725 0.67829 3.9225 1.01829 3.8725L3.8383 3.41249C4.026 3.37122 4.20303 3.29119 4.35801 3.17754C4.51299 3.06388 4.64253 2.91911 4.73832 2.75249L6.06828 0.2325C6.23828 -0.0775 6.4883 -0.0775 6.64829 0.2325L7.95829 2.77249C8.05684 2.93686 8.18723 3.07991 8.34178 3.19324C8.49632 3.30657 8.67193 3.38791 8.85832 3.4325L11.6783 3.91249C12.0083 3.97249 12.0883 4.2225 11.8483 4.4625L9.84831 6.4625C9.72089 6.60605 9.62413 6.77412 9.56394 6.95639C9.50376 7.13866 9.48139 7.33129 9.49827 7.52249L9.9083 10.3425Z"
          fill="#ECB62B"
        />
      </svg>
    </span>
  );

  const ReviewCard = (data: any) => {
    const cardData = data.data;
    return (
      <div className="item">
        <div className="p-3 bg-white mr-3 card-sec d-flex flex-column justify-content-between">
          <p>{cardData.content}</p>
          <div className="row">
            <div className="col-auto">
              <img src={cardData.img} alt="user" srcSet="" />
            </div>
            <div className="col pl-0">
              <h6 className="mb-0">{cardData.name}</h6>
              <div className="star">
                {Array.from({ length: cardData.rating }, (_, i) => (
                  <StarSvg />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const history = useHistory();
  const [disablePhone, setDisablePhone] = useState<boolean>(false);
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const [playVideo1, setPlayVideo1] = useState<boolean>(false);
  const [showPause, setShowPause] = useState<boolean>(false);
  const [showPause1, setShowPause1] = useState<boolean>(false);
  const [otpSent, setOtpSent] = useState(false);
  const videoRef = useRef<any>();
  const videoRef1 = useRef<any>();
  const [otp, setOtp] = useState<any>();
  const termsRef = useRef<any>();
  const [seconds, setSeconds] = useState(60);
  const [errorOtp, setErrorOtp] = useState(false);
  const [phoneInput, setPhoneInput] = useState<IPhone>({
    phone: "",
    countryCode: "+91",
    utms: {
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      utm_content: "",
    },
  });
  const [id, setId] = useState<any>("");
  const [formDetails, setFormDetails] = useState<IRegister>({
    country: "India",
    firstName: "",
    lastName: "",
    email: "",
    ageGroup: "",
    state: "",
    instrument: "",
  });

  const [error, setError] = useState<any>();
  const [resendMessage, setResendMessage] = useState<boolean>(false);
  const [hideMessage, setHideMessgae] = useState<boolean>(true);
  const [hideResendText, setHideResendText] = useState<boolean>(false)

  const onChange = (e: any) => {
    setFormDetails({
      ...formDetails,
      [e.target.name]: e.target.value,
    });
  };

  function handleSendOtp() {
    sendOtp(phoneInput, setOtpSent, setSeconds, setError, setId)
  }



  function handlePhoneNumberChange(e: any, data: any) {
    // Allow changing the phone number only if OTP is not sent yet.
    if (!otpSent) {
      setPhoneInput({
        ...phoneInput,
        phone: e.slice(data.dialCode.length),
        countryCode: "+" + data.dialCode,
      });
    }
  }

  useEffect(() => {
    let interval: any;
    if (otpSent && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [otpSent, seconds, disablePhone]);

  useEffect(() => {
    if (seconds <= 0) {
      setOtpSent(false)
    }
  }, [seconds]);


  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utm_source = searchParams.get("utm_source") as string;
    const utm_medium = searchParams.get("utm_medium") as string;
    const utm_campaign = searchParams.get("utm_campaign") as string;
    const utm_content = searchParams.get("utm_term") as string;
    setPhoneInput({
      ...phoneInput,
      utms: {
        utm_source: utm_source || "",
        utm_medium: utm_medium || "",
        utm_campaign: utm_campaign || "",
        utm_content: utm_content || "",
      }
    })
  }, [window.location.search])


  return (
    <div className="studentNew">
      <nav className="navbar navbar-expand-lg" id="top">
        <Link className="navbar-brand py-4" to="#">
          <img src={logo} alt="Dhunguru" />
        </Link>
      </nav>

      <section>
        <div className="container mt-3 mt-lg-4">
          <div className="row">
            <div className="col-lg-7 mb-4 mb-lg-5 order-2 order-lg-1">
              <div className="d-none d-lg-block">
                <h1 className="for-all-ages">
                  Live Music Classes{" "}
                  <i>
                    For All Ages
                    <img src={line} alt="" aria-disabled="true" />
                  </i>
                </h1>
                <div className="mt-3">
                  {playVideo ? (
                    <div
                      className="position-relative"
                      onClick={() => {
                        const player = videoRef.current.player.player.player;
                        player.paused ? player.play() : player.pause();
                        player.paused
                          ? setShowPause(true)
                          : setShowPause(false);
                      }}
                    >
                      <div hidden={!showPause}>
                        <AiFillPlayCircle className="video-play-btn" />
                      </div>
                      <ReactPlayer
                        ref={videoRef}
                        url="https://www.youtube.com/watch?v=sr1d1MZTv9Y"
                        playing={playVideo}
                        className="video-player"
                      />
                    </div>
                  ) : (
                    <div
                      className="position-relative"
                      onClick={() => {
                        setPlayVideo(true);
                      }}
                    >
                      <AiFillPlayCircle className="video-play-btn" />
                      <img
                        src={video}
                        alt=""
                        srcSet=""
                        className="w-100 video-thumbnail"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-auto col-12 mb-3">
                  <div className="interactive p-3">
                    <div className="row">
                      <div className="col-auto pr-0">
                        <img src={interactive} alt="1:1 Interactive Classes" />
                      </div>
                      <div className="col">
                        <b className="d-block">1:1 Interactive</b>
                        Classes
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-auto col-12 mb-3">
                  <div className="lifetime p-3">
                    <div className="row">
                      <div className="col-auto pr-0">
                        <img src={lifetime} alt="Lifetime Access" />
                      </div>
                      <div className="col">
                        <b className="d-block">Lifetime Access</b>
                        to Session Recordings
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg col-12 mb-3">
                  <div className="flexible p-3">
                    <div className="row">
                      <div className="col-auto pr-0">
                        <img src={flexible} alt="Flexible Timings" />
                      </div>
                      <div className="col">
                        <b className="d-block">Flexible</b>
                        Timings
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-2">
                  <OwlCarousel
                    className="owl-theme review-sec"
                    loop={true}
                    margin={10}
                    autoplay={true}
                    autoplayTimeout={3000}
                    dots={false}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      600: {
                        items: 2,
                      },
                      1000: {
                        items: 2.25,
                      },
                    }}
                  >
                    {reviewData.map((review: any) => (
                      <ReviewCard data={review} />
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mb-4 mb-lg-5 order-1 order-lg-2 pl-3 pl-xl-5">
              <div className="mb-4 d-block d-lg-none">
                <h1 className="for-all-ages">
                  Live Music Classes
                  <i>
                    For All Ages
                    <img src={line} aria-disabled="true" alt="" />
                  </i>
                </h1>
                <div className="mt-3">
                  {playVideo1 ? (
                    <div
                      className="position-relative"
                      onClick={() => {
                        const player = videoRef1.current.player.player.player;
                        player.paused ? player.play() : player.pause();
                        player.paused
                          ? setShowPause1(true)
                          : setShowPause1(false);
                      }}
                    >
                      <div hidden={!showPause1}>
                        <AiFillPlayCircle className="video-play-btn" />
                      </div>
                      <ReactPlayer
                        ref={videoRef1}
                        url="https://www.youtube.com/watch?v=sr1d1MZTv9Y"
                        playing={playVideo1}
                        className="video-player"
                      />
                    </div>
                  ) : (
                    <div
                      className="position-relative"
                      onClick={() => {
                        setPlayVideo1(true);
                      }}
                    >
                      <AiFillPlayCircle className="video-play-btn" />
                      <img
                        src={video}
                        alt=""
                        srcSet=""
                        className="w-100 video-thumbnail"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="bg-white p-4 shadow form-sec">
                <h3>Sign up For Free Demo</h3>
                <div className="form-group mt-3">
                  <label className="small mb-1 mt-3">Phone Number</label>
                  <div className="row phone-sec mb-3">
                    <div className="col pr-0">
                      <div className="input-group">
                        <PhoneInput
                          country={"in"}
                          onChange={(e: any, data: any) => {
                            handlePhoneNumberChange(e, data);
                          }}
                          disabled={otpSent || disablePhone}
                          inputClass={`form-control ${disablePhone ? "border-success" : ""
                            }`}
                        />
                      </div>
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-warning"
                        disabled={otpSent || disablePhone}
                        onClick={() => handleSendOtp()}
                      >
                        Send OTP
                      </button>
                    </div>
                  </div>
                  <div className="row phone-sec">
                    <div className="col pr-0">
                      <div className="input-group">
                        <input
                          type="text"
                          name="otp"
                          disabled={disablePhone}
                          className={`form-control ${disablePhone ? "border-success" : ""
                            }`}
                          onChange={(e: any) => setOtp(e.target.value)}
                        />
                      </div>
                      {errorOtp && (
                        <div className="text-right small mt-1 text-danger">
                          Incorrect OTP{" "}
                        </div>
                      )}

                      {/* otp send text */}
                      {otpSent && hideMessage && (
                        <div className="text-right small mt-1">
                          {`OTP has been sent to your mobile number and will expire in ${seconds} seconds`}
                        </div>
                      )}

                      {/* otp resend text */}
                      {resendMessage && !hideResendText && (
                        <div className="text-right small mt-1">
                          {`OTP has been sent to your mobile number`}
                        </div>
                      )}
                    
                      {!resendMessage && !otpSent && seconds <= 0 && !disablePhone && (
                        <div className="text-right small mt-1">
                          Didn't received a code ?{" "}
                          <span
                            className="resend-otp"
                            onClick={() => {
                              !disablePhone &&
                                resendOtp(id, setResendMessage, setHideMessgae);
                            }}
                          >
                            Resend
                          </span>
                        </div>
                      )}

                 


                      {/* verified text */}
                      <div className="text-right small mt-1 text-success">
                        {otpSent && hideMessage === false && "Verified"}
                      </div>


                      {error && error.response && error.response.status === 404 &&
                        <div className="text-left small mt-2">
                          User already exist!{" "}
                          <a href="https://dhunguru.com/login">Login</a>
                        </div>}
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-primary"
                        disabled={disablePhone}
                        onClick={() => {
                          verifyOtp(otp, id, setOtpSent, setHideMessgae, setHideResendText, setErrorOtp);
                        }}
                      >
                        Verify Code
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  {/* <div className="text-right small mt-2 text-danger">
                    {"You are already registered with us, please login."}
                  </div> */}
                  <label className="small mb-1 mt-3">Your Name</label>
                  <div className="row">
                    <div className="col pr-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        name="firstName"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col pl-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        name="lastName"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="small mb-1 mt-3">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="example@gmail.com"
                    name="email"
                    onChange={onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="city" className="small mb-1 mt-3">
                    Instrument
                  </label>
                  <select
                    id="instrument"
                    name="instrument"
                    className="form-control"
                    onChange={onChange}
                  >
                    <option value="" disabled selected hidden>
                      Select an instrument
                    </option>
                    {instruments.map((instrument) => (
                      <option value={instrument.value}>
                        {instrument.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="row">
                  <div className="col-md-4 col-6 pr-lg-2">
                    <div className="form-group">
                      <label className="small mb-1 mt-3">Age Group</label>
                      <select
                        id="age-group"
                        name="ageGroup"
                        className="form-control"
                        onChange={onChange}
                      >
                        <option value="" disabled selected hidden>
                          Age
                        </option>
                        {age_groups.map((age) => (
                          <option value={age.value}>{age.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 pl-lg-2 pr-lg-2">
                    <div className="form-group">
                      <label htmlFor="city" className="small mb-1 mt-3">
                        Country
                      </label>
                      <select
                        id="country"
                        name="country"
                        className="form-control"
                        onChange={onChange}
                      >
                        {countries.map((country) => (
                          <option
                            value={country}
                            selected={country === "India"}
                          >
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {formDetails.country === "India" && (
                    <div className="col-md-4 col-6">
                      <div className="form-group">
                        <label htmlFor="state" className="small mb-1 mt-3">
                          State
                        </label>
                        <select
                          id="state"
                          name="state"
                          className="form-control"
                          onChange={onChange}
                        >
                          <option value="" disabled selected hidden>
                            State
                          </option>
                          {states.map((state) => (
                            <option value={state}>{state}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
                <div className="custom-control custom-checkbox form-group d-flex align-items-start  mt-3">
                  <input
                    type="checkbox"
                    className="custom-control-input me-2 mt-1"
                    id="customCheck1"
                    ref={termsRef}
                  />
                  <label
                    className="custom-control-label small"
                    htmlFor="customCheck1"
                  >
                    By Continuing, you agree to Dhunguru's{" "}
                    <a href="https://dhunguru.com/legal#tnc-students">
                      Terms of Use
                    </a>
                    ,{" "}
                    <a href="https://dhunguru.com/legal#privacy">
                      Privacy Policy{" "}
                    </a>
                    and
                    <a href="https://dhunguru.com/refund-policy">
                      {" "}
                      Refund Policy
                    </a>
                  </label>
                </div>
                <div className="form-group mb-2 mt-3">
                  <button
                    className="btn btn-primary btn-block w-100"
                    onClick={async () => {
                      if (termsRef.current.checked) {
                        const register = await registerUser(formDetails, id, phoneInput.utms);
                        if (register) {
                          window.location.replace("https://dhunguru.com/");
                        }
                      } else toast.warn("Agree to the terms to continue");
                    }}
                  >
                    Sign Up
                  </button>
                </div>
                <div className="text-center small mt-2">
                  Already have an account?{" "}
                  <a href="https://dhunguru.com/login">Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegisterStudent;
