import { ToastContainer } from "react-toastify";
import "./App.css";
import AppRouter from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import ReactGA4 from "react-ga4";

ReactGA4.initialize("G-LNHQY72YCG");

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AppRouter />
    </div>
  );
}

export default App;
