export const reviewData = [
  {
    name: "Akanksha Rawat",
    content:
      "Amazing Platform to learn and explore your interest of instrument. Great teachers Amulya my teacher is the best violin teacher i could've gotten. The best part is it is according to your convenience and timing, as a physio it was hard for me to find time but they managed to give me lessons accordingly… I'm very thankful to this whole wonderful team of Dhunguru for making my days less stressful and more blissful.",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5RlifjwkOnTWmO5zJgBp6AhvysSqd28qD6QbCEoJq8=w43-h43-p-rp-mo-ba2-br100",
    rating: 5,
  },
  {
    name: "Kiara Agarwal",
    content:
      "So the classes are really amazing and the sir and mama's are really kind and they help me in every step and I will recommend Dhunguru to everyone",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5T7i69rpB7s9STcNQBfGiTKtHSXUTG1IZ9mtmPnIQ=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "nileena dharan",
    content:
      "Highly satisfied with the Operations of Dhunguru.Special call out to Poonam Arora for smoothly handling the concerns. Very Accomodative and understanding staff. I would recommend Dhunguru  to my known ones.",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5SzfIwm0B3_0xNuHryo3u9VsTjyT45lrYEDucokyw=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "john david",
    content:
      "Great place to learn music and musical instruments.flexible in terms of session based classes , so that you can take classes when you are available. All classes are recorded and tutors are good  and highly skilled. Demo classes are free of charge .",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5ScsXLWs4ngycOUG3xe0ooZC6CCwyvHu4gV-vyi7Q=w43-h43-p-rp-mo-ba2-br100",
    rating: 5,
  },
  {
    name: "Karishma Art & Craft",
    content:
      "Thank you Dhunguru music .Very good platform for kids to learn music. Highly recommend",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5RW3KRHEdeswAe2I-6JKT9I3NK4TWY6_RuYFWolYg=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "purushottam kumar",
    content: "Best platform to learn online music. Mouli ma'am is very good.",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5Q2W6LOSohPv2F8wzP7iPfiodUUWOw9f9v3oi57=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "Neha Mathuria",
    content:
      "DhunGuru have a unique imagination and have come up with some of the most creative ideas I’ve ever seen.",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5RkUCv_RW-DNqlAh76TLYHttUtQ43U_D-T0DFYt=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "kirti tandon",
    content:
      "I had a wonderful experience it is so useful for conceptualising and rectifying technicalities",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5TaUHAu7-3GjJtVMa69iFfZ53XsY9-QP9zofVrhpQ=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "Snigdha Dar",
    content:
      "It's a user friendly website, once you browse you will get a call from the dhun guru team and they will guide you they the process and arrange for demo class/classes and then you can select the guru aad per your preference and like. overall good experience. The guru we selected is very nice and patient and knows his craft .",
    img: "https://lh3.googleusercontent.com/a/AGNmyxbL8bG7XVqCDyYYTQwb3AIerUZOEquh14i3qTQT=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "Rajat labroo",
    content:
      "I am literally enjoying this journey with Mouli mam , my experience is very overwhelming Mouli mam is absolutely exquisite personality and I am blessed to have such Guru ! Her pedagogy , methods and patience is phenomenal.. I am looking forward to join another advance session in future with mouli mam , so that I can enhance my singing even more than before .",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5QpbR_utl5L8C2gk_omdqDtnmCx_HCmtHK7rRuh=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "Faritha Banu",
    content:
      "Excellent platform to learn music and instruments. I felt very happy and comfortable to learn flute from home.  Thanks to Dhunguru Music and many thanks to guru Aditya Patil.",
    img: "https://lh3.googleusercontent.com/a/AGNmyxYwmfqVo-HOAhRy8dvPVOSuhGQaut5IvMz2PjPG=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "Vaishali Pal",
    content:
      "A good experience of teaching online... Wherever the student is.... Distance matters least.. flexibility of timing is there and sessions can be rescheduled.. and the councelors and peopl working at the backened are really helpful ....sometimes network fluctuations happen.. but overall it's a new age way of personalised learning...speciallyy vikhyat has been really helpful throughout 😇",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5TpyO8G1tMQRUNIUc1enmB9vm-6Iv4zdGljQuZZ6Q=w43-h43-p-rp-mo-br100",
    rating: 4,
  },
  {
    name: "Rajnish Mandal",
    content:
      "I had a wonderful experience while short engagement with Dhun guru. Very punctual and systematic approach. The website is very well organised and we can easily get access to old classes for the purpose of revision. I am truly satisfied and surely recommend others. It’s total value for money",
    img: "https://lh3.googleusercontent.com/a/AGNmyxakFRJj02cXCXplX2hk-qUUYH8Utqc9_CPhmEZk=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "Sreenivas Goparaju Rao",
    content:
      "Dhunguru is a superb platform for those aspiring to learn music. The faculty is very cordial and friendly with fantastic teaching techniques.  I felt very comfortable in following instructions of my teacher Deepanshu.  Thanks to Dhunguru for providing such an interface.",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5TEXzZJCEtuXVdDXGoi2v056xTwXnq6Jzy_MF55jQ=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "prakash rajamannar",
    content:
      "Dhunguru, the online platform for learning music.  We were looking for online platform for my son during corona time since we dint want to send for offline class. We started with a demo class with Amulya singh mam and that's when the journey started. We were really impressed with the class, the journey is continuing for more than a year now. I don't see any difference between online or offline due to the way dhunguru and teachers with the way they are conducting class. We are 200% satisfied and suggest dhunguru for any online music learning. Good job dhunguru.",
    img: "https://lh3.googleusercontent.com/a/AGNmyxYODWRnaljVAxEWRYdwl6B681F9jBqztEy1vYLZ=w43-h43-p-rp-mo-ba4-br100",
    rating: 5,
  },
  {
    name: "Arshad Raza",
    content:
      "Dhunguru Music is great place to learn music and instruments as it offers both professionally enriched and friendly team and instructors which eases out your journey to learn a new skill.",
    img: "https://lh3.googleusercontent.com/a/AGNmyxZAwvOoMQZwl9E5I1hxwKGgIeyMgBBEyPBo3IBZ=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "Kshitij Jolly",
    content:
      "Excellent online platform to learn any music skill. Rajat sir has really helped me hone my singing skills and take it to the next level.",
    img: "https://lh3.googleusercontent.com/a/AGNmyxZ8GSboUxakGcwkJXK-0YBlXnbFZZrEiDdOAVoM=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "Rajat Dhingra",
    content:
      "I have been teaching in Dhunguru music for past 1.5 years. The experience is very good. All the Coordinators and staff members are very helpful and respond very quickly",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5QjZ6UYph1Dmw3TJq4NJ2HvRgqU32yypmnGTrC0=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "BIDISHA SENGUPTA",
    content:
      "I'm extremely happy to find a wonderful platform like Dhunguru where one can learn music at their own time, place and pace. Dhunguru team is friendly,courteous and helpful. The mentor for classical music for which my son enrolled is an awesome Guru who is knowledgeable,he knows how to grab attention of his student and deal in a friendly manner while going through the lesson without breaking it's pattern. So I really appreciate Rajat Dhingra Sir and applaud Dhunguru for this wonderful experience for my son. Thanks a lot for this experience.",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5TeZMdzXcrfEooddIGr_a9NJ4WYAabfQZ5aUFTs=w43-h43-p-rp-mo-ba2-br100",
    rating: 5,
  },
  {
    name: "Sai Kiran",
    content:
      "Best thing about Dhunguru is, we are provided with an option of choosing our guru, before proceeding with the classes. The classes are affordable and it's a nice platform to all the music lovers and learners. I have improved my skills in Cajon with Yashab ji as my Guru and now this guru has become a friend for life 🙌. Thank you Dhunguru for this service and special thanks to Vikhyat ji for his patience, by sending me reminders before every class 😀",
    img: "https://lh3.googleusercontent.com/a/AGNmyxaP_EI6Ic8yvNsi1LEqVmr--_YWfb2C1gQlRTNc=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "Cookinstories",
    content:
      "Till now it's going great with dhunguru and hopefully looking forward to see my daughter who is 6 yrs can learn more and can be a professional level singer one day!!!",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5Tgm9HcjkARJ5UEFB7f2csA8hKv2x58PlAIS2snYw=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "Bhawna",
    content:
      "I like the convince of scheduling according to availability. Recorded sessions that can be viewed later.",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5SqTnQhPXDfMilJYpD_DCszPTqdy96GRK8lQ4bHdw=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
  {
    name: "Neha Mathuria",
    content:
      "DhunGuru have a unique imagination and have come up with some of the most creative ideas I’ve ever seen.",
    img: "https://lh3.googleusercontent.com/a-/ACB-R5RkUCv_RW-DNqlAh76TLYHttUtQ43U_D-T0DFYt=w43-h43-p-rp-mo-br100",
    rating: 5,
  },
];
