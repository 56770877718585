import ReactGA4 from "react-ga4";

const TrackGoogleAnalyticsEvent = (
    eventName: string
) => {
    ReactGA4.event(eventName, { method: "button" });

};

export { TrackGoogleAnalyticsEvent };
