import { toast } from "react-toastify";
import { validateEmail } from "../utils/validation";
import axios from "axios";
import { TrackGoogleAnalyticsEvent } from "../utils/ga4";

let api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API || "https://online.dhunguru.com/api",
});

export const sendOtp = async (phoneDetails: any, setOtpSent: any, setSeconds: any, setError: any, setId: any) => {
  await api({
    method: "post",
    url: "/auth/sent-otp",
    data: phoneDetails,
  })
    .then((res) => {
      if (res.status === 200) {
        setId(res.data)
        setOtpSent(true);
        setSeconds(60)
      }
    })
    .catch((err) => {
      toast.error(err.data);
      setError(err);
    });
};

export const verifyOtp = async (
  otp: number,
  id: any,
  setOtpSent: any,
  setHideMessgae: any,
  setHideResendText: any,
  // setId: any
  setErrorOtp: any
  ) => {
    await api({
      method: "post",
      url: "/auth/verify-otp",
      data: { _id: id, otp: Number(otp) },
    })
    .then((res) => {
      // setId(res.data._id);
      if (res.status === 200) {
        TrackGoogleAnalyticsEvent("otp_verified")
        setOtpSent(true);
        setHideMessgae(false);
        setHideResendText(true);
        setErrorOtp(false);
      }
    })
    .catch((err) => {
      setErrorOtp(true)
      return 0;
    });
};

export const resendOtp = async (
  id: any,
  setResendMessage: any,
  setHideMessgae: any
) => {
  await api({
    method: "post",
    url: "/auth/otp-resend",
    data: { _id: id },
  })
    .then((res) => {
      if (res.status === 200) {
        setResendMessage(true);
        setHideMessgae(false);
      }
    })
    .catch((err) => {
      return 0;
    });
};



export const registerUser = async (userDetails: any, id: any, utms: any) => {
  if (
    userDetails.firstName === "" ||
    userDetails.lastName === "" ||
    userDetails.email === "" ||
    userDetails.ageGroup === "" ||
    (userDetails.country === "India" && userDetails.state === "") ||
    userDetails.instrument === ""
  ) {
    toast.error("All fields are required");
    return false;
  }
  if (!validateEmail(userDetails.email)) {
    toast.error("Invalid Email");
    return false;
  }
  await api({
    method: "post",
    url: "/auth/user-register",
    data: { data: { ...userDetails, phoneId: id }, utms: utms },
  })
    .then((res) => {
      if (res.status == 200) {
        TrackGoogleAnalyticsEvent("signup_complete")
        toast.success("User registered successfully");
        window.location.replace("https://dhunguru.com/")
        return true;
      } else {
        toast.error(res.data);
        return false;
      }
    })
    .catch((err) => {
      toast.error(err.data);
      return false;
    });
};
