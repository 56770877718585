import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import RegisterStudent from "../Pages/Register";

const AppRouter = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/">
            <RegisterStudent />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default AppRouter;
